import { Fragment } from 'react'
import {
  TextInput,
  NumberInput,
  FileInput,
  SelectInput,
  ImageInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  minLength,
  maxValue,
  maxLength,
  minValue,
  FormDataConsumer,
  AutocompleteInput,
  SimpleForm,
  usePermissions,
  useGetOne,
} from 'react-admin'
import { useWatch } from 'react-hook-form'
import Grid from '@mui/material/Grid'

import CustomFileField from '../../components/CustomFileField'
import CustomImageField from '../../components/CustomImageField'
import EnglishBookToolbar from '../../components/EnglishBookToolbar'
import { validateRequired, validateUnique } from '../../utils/validate'
import CustomFileInput from '../../components/CustomFileInput'
import GridContainer from '../../components/GridContainer'
import { ROLES } from '../../components/constants'
import PeriodCommissionsInput from '../../components/PeriodCommissionsInput'

import { PRICE_CHOICES } from './priceTiers'

const levelOptions = [
  { id: 1, name: 'Beginner' },
  { id: 2, name: 'Intermediate' },
  { id: 3, name: 'Advanced' },
]

const CommissionField = () => {
  const { permissions } = usePermissions()
  const validateCommission = [minValue(0), maxValue(100)]

  const publisherId = useWatch({
    name: 'publisherId',
  })

  const { data: user, isLoading } = useGetOne('users', { id: publisherId })

  return (
    (permissions === ROLES.ADMIN || permissions === ROLES.QC) && (
      <>
        <Grid item xs={4}>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData || isLoading) return null
              return (
                <NumberInput
                  label={`Commission (0-100) / default: ${
                    user?.defaultCommission
                  }%`}
                  source="commission"
                  fullWidth
                  validate={validateCommission}
                  variant="standard"
                />
              )
            }}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12}>
          <PeriodCommissionsInput
            fullWidth
            variant="standard"
            label="Period commissions"
            source="commissions"
          />
        </Grid>
      </>
    )
  )
}

const EnglishBookForm = props => {
  const { renderCoverImage, renderSampleFile, isCreating, ...rest } = props
  const { permissions } = usePermissions()

  const validateDescription = [
    validateRequired('Description'),
    minLength(100),
    maxLength(2000, 'Do not enter more than 2000 characters'),
  ]

  const validateChapterOrder = [
    validateRequired('Order'),
    minValue(1),
    maxValue(1000),
  ]

  return (
    <SimpleForm
      {...rest}
      toolbar={<EnglishBookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        {!isCreating && (
          <Fragment>
            <Grid container item xs={4}>
              <TextInput
                disabled
                fullWidth
                source="id"
                variant="standard"></TextInput>
            </Grid>
            <Grid container item xs={8}>
              <TextInput
                disabled
                fullWidth
                source="productId"
                variant="standard"></TextInput>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={6}>
          <SelectInput
            label="Is free"
            source="isFree"
            choices={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' },
            ]}
            required
            defaultValue={false}
            fullWidth
            variant="standard"
            margin="normal"
          />
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            if (!formData || !formData.isFree) return null

            return (
              <Grid item xs={12}>
                <TextInput source="whyItFree" fullWidth variant="standard" />
              </Grid>
            )
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => {
            if (!formData || formData.isFree) return null

            return (
              <Grid item xs={12}>
                <SelectInput
                  label="Is membership included"
                  source="membershipIncluded"
                  choices={[
                    { id: true, name: 'Yes' },
                    { id: false, name: 'No' },
                  ]}
                  required
                  defaultValue={false}
                  fullWidth
                  variant="standard"
                  margin="normal"
                />
              </Grid>
            )
          }}
        </FormDataConsumer>

        <Grid item xs={12}>
          <TextInput
            source="title"
            fullWidth
            validate={validateRequired('Title')}
            variant="standard"
          />
        </Grid>

        <Grid item xs={4}>
          <ReferenceInput
            label="Primary category"
            source="primaryCategoryId"
            filter={{
              type: 'english_book',
              primary: true,
            }}
            reference="categories">
            <AutocompleteInput
              optionText="name"
              fullWidth
              required
              variant="standard"
              validate={validateRequired('Primary category')}
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                type: 'english_book',
                primary: true,
              })}
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={8}>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData.primaryCategoryId) return null
              return (
                <ReferenceArrayInput
                  label="Secondary categories"
                  source="secondaryCategoryIds"
                  filter={{
                    type: 'english_book',
                    primary: true,
                    parentId: formData.primaryCategoryId,
                  }}
                  reference="categories">
                  <AutocompleteArrayInput
                    optionText="name"
                    fullWidth
                    required
                    variant="standard"
                    filterToQuery={searchText => ({
                      name: { $iLike: `%${searchText}%` },
                      type: 'english_book',
                      primary: false,
                      parentId: formData.primaryCategoryId,
                    })}
                  />
                </ReferenceArrayInput>
              )
            }}
          </FormDataConsumer>
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            label="Level"
            source="level"
            choices={levelOptions}
            required
            fullWidth
            variant="standard"
            margin="normal"
            validate={validateRequired('Level')}
          />
        </Grid>

        <Grid item xs={12}>
          <ReferenceArrayInput
            label="Topics"
            source="topicIds"
            reference="topics"
            filter={{
              type: 'english_book',
            }}>
            <AutocompleteArrayInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                type: 'english_book',
              })}
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={6}>
          <ReferenceArrayInput
            label="Authors"
            source="authorIds"
            reference="authors">
            <AutocompleteArrayInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
              validate={validateRequired('Authors')}
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
              })}
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={6}>
          <ReferenceArrayInput
            label="Voice actors"
            source="voiceActorIds"
            reference="voice-actors">
            <AutocompleteArrayInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
              })}
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={4}>
          <ReferenceInput
            label="Publisher"
            source="publisherId"
            reference="users"
            filter={{
              role: 'publisher',
            }}>
            <SelectInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
              validate={validateRequired('Publisher')}
              perPage={1000}
            />
          </ReferenceInput>
        </Grid>

        <CommissionField />

        <Grid item xs={12}>
          <SelectInput
            source="price"
            choices={PRICE_CHOICES}
            required
            fullWidth
            variant="standard"
            margin="normal"
            validate={validateRequired('Price')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Nhà xuất bản"
            source="responsiblePublisher"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Nhà xuất bản điện tử"
            source="digitalPublisher"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Nhà xuất bản Sách giấy"
            source="paperPublisher"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Chịu trách nhiệm xuất bản, nội dung"
            source="responsibleContent"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Chịu trách nhiệm bản thảo"
            source="manuscriptCurator"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Biên tập"
            source="editorAndPrintEditing"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="publishDecisionNumber"
            label="Số quyết định xuất bản"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="publishConfirmationNumber"
            label="Số xác nhận đăng ký xuất bản"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Số ISBN"
            source="isbnNumber"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="depositary"
            label="Nộp lưu chiểu"
            fullWidth
            variant="standard"
          />
        </Grid>

        {/* <Grid item xs={8}>
          <SelectInput
            source="discountType"
            fullWidth
            variant="standard"
            choices={[
                  console.log("🚀 ~ EnglishBookForm ~ formData:", formData)
              { id: null, name: 'None' },
              { id: 'direct', name: 'Direct' },
              { id: 'percentage', name: 'Percentage' },
            ]}
          />
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData) return null

              let price = formData && formData.price
              if (formData.discountType) {
                if (formData.discountType === 'percentage') {
                  price = price - price * (formData.discount / 100)
                } else {
                  price = price - formData.discount
                }
              }
              return (
                <div className={classes.totalPrice}>
                  Total after discount: {Number(price || 0).toLocaleString()}{' '}
                  VND
                </div>
              )
            }}
          </FormDataConsumer>
        </Grid> */}

        {/* <Grid item xs={4}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (!formData || !formData.discountType) return null
              const validate = [
                validateRequired('Discount'),
                minValue(0),
                maxValue(
                  formData.discountType === 'direct' ? formData.price : 100,
                ),
              ]

              return (
                <NumberInput
                  source="discount"
                  validate={validate}
                  fullWidth
                  variant="standard"
                  {...rest}
                />
              )
            }}
          </FormDataConsumer>
        </Grid> */}

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            validate={validateDescription}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <ArrayInput
            source="audiences"
            label="Who's it for"
            fullWidth
            variant="standard">
            <SimpleFormIterator>
              <TextInput
                fullWidth
                variant="standard"
                source="audience"
                label="Text"
                validate={validateRequired('Text')}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ArrayInput
            source="attachments"
            fullWidth
            variant="standard"
            validate={validateUnique('name')}>
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ formData }) => {
                  if (!formData || !formData.chapters) return null

                  return (
                    <SelectInput
                      label="Chapter"
                      source={'englishBookChapterId'}
                      fullWidth
                      variant="standard"
                      choices={formData.chapters.filter(
                        chapter => chapter && chapter.id,
                      )}
                    />
                  )
                }}
              </FormDataConsumer>

              <TextInput
                source="name"
                label="Name"
                fullWidth
                variant="standard"
                validate={validateRequired('Title name')}
              />
              <CustomFileInput
                source="url"
                label="File"
                type="attachment"
                accept="text/*, image/*, application/msword, application/pdf"
                upload={true}
                validate={validateRequired('Audio file')}>
                <CustomFileField source="src" title="attachment" canDownload />
              </CustomFileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          {renderCoverImage ? (
            renderCoverImage(props)
          ) : (
            <ImageInput
              source="coverImageUrl"
              label="Cover image"
              accept="image/*"
              multiple={false}
              validate={validateRequired('Cover Image')}>
              <CustomImageField source="src" title="Cover image" />
            </ImageInput>
          )}
        </Grid>

        <Grid item xs={12}>
          {renderSampleFile ? (
            renderSampleFile(props)
          ) : (
            <FileInput
              source="fileSampleUrl"
              label="File sample"
              multiple={false}
              validate={validateRequired('File Sample')}>
              <CustomFileField
                source="src"
                title="File sample"
                canDownload={permissions === ROLES.ADMIN}
              />
            </FileInput>
          )}
        </Grid>

        <Grid item xs={12}>
          <FileInput
            source="fileSampleSubtitleUrl"
            label="File sample subtitle"
            accept=".srt, .sub, .vtt, .csv"
            multiple={false}
            validate={validateRequired('File Sample Subtitle')}>
            <CustomFileField
              source="src"
              title="File sample subtitle"
              canDownload={permissions === ROLES.ADMIN}
            />
          </FileInput>
        </Grid>
      </GridContainer>

      <ArrayInput
        source="chapters"
        fullWidth
        validate={[
          validateRequired('Chapters'),
          validateUnique('order', true),
        ]}>
        <SimpleFormIterator>
          <TextInput
            source="order"
            label="Order"
            variant="standard"
            validate={validateChapterOrder}
          />
          <TextInput
            source="name"
            label="Name"
            fullWidth
            variant="standard"
            validate={validateRequired('name')}
          />
          <CustomFileInput
            source="url"
            label="File"
            type="chapter"
            upload={true}
            validate={validateRequired('Chapter file')}>
            <CustomFileField
              source="src"
              title="chapter"
              variant="standard"
              canDownload={permissions === ROLES.ADMIN}
            />
          </CustomFileInput>

          <CustomFileInput
            source="subtitleUrl"
            label="Subtitle file"
            type="subtitle"
            accept=".srt, .sub, .vtt, .csv"
            upload={true}
            validate={validateRequired('Subtitle file')}>
            <CustomFileField
              source="src"
              title="Subtitle file"
              canDownload={permissions === ROLES.ADMIN}
            />
          </CustomFileInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  )
}

export default EnglishBookForm
