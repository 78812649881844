import {
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
  NumberInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import { BOOK_STATUSES } from '../../components/constants'

const STATUS = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'InActive' },
]

const FeaturedContentForm = props => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <ArrayInput source="names" name="names">
            <SimpleFormIterator inline>
              <TextInput name="" source="" />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={4}>
          <SelectInput
            label="Status"
            source="status"
            choices={STATUS}
            variant="standard"
            required
            fullWidth
            validate={validateRequired('Status')}
          />
        </Grid>

        <Grid item xs={12}>
          <ArrayInput
            label="Content List"
            source="items"
            fullWidth
            validate={[validateRequired('Items')]}>
            <SimpleFormIterator>
              <NumberInput name="" source="order" />
              <ReferenceInput
                label="Books"
                source={'bookId'}
                reference="books"
                filter={{
                  status: BOOK_STATUSES.PUBLISHED,
                }}
                name="book">
                <AutocompleteInput
                  optionText="title"
                  options={{
                    fullWidth: true,
                  }}
                  variant="standard"
                  filterToQuery={searchText => ({
                    title: { $iLike: `%${searchText}%` },
                    status: BOOK_STATUSES.PUBLISHED,
                  })}
                />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default FeaturedContentForm
