import { Create } from 'react-admin'

import AdsForm from './AdsForm'

const AdsCreate = props => (
  <Create redirect="list" {...props}>
    <AdsForm />
  </Create>
)

export default AdsCreate
