import {
  List,
  Datagrid,
  DateField,
  TextField,
  EditButton,
  useRecordContext,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CodActionButton from '../../components/CodActionButton'

const CustomActionField = () => {
  const record = useRecordContext()

  if (!record) return null

  if (record.canceled) {
    return <span>Canceled</span>
  }

  if (!record.confirm) {
    return (
      <Grid item xs={6} container alignItems="center">
        <EditButton record={record} />
        <CodActionButton
          action="confirm"
          label="Confirm"
          record={record}
          color="primary"
        />
        <CodActionButton
          action="cancel"
          label="Cancel"
          record={record}
          color="secondary"
        />
      </Grid>
    )
  }

  return <EditButton record={record} />
}

const CrmRevenueReportList = props => {
  return (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="sessionId" />
        <TextField source="payment_order.productName" label="Product name" />
        <TextField source="recipientName" />
        <TextField source="recipientPhone" />
        <TextField source="recipientAddress" />
        <DateField source="updatedAt" label="Updated At" />
        <DateField source="createdAt" label="Created At" />
        <TextField source="trackingNumber" />
        <CustomActionField />
      </Datagrid>
    </List>
  )
}

export default CrmRevenueReportList
