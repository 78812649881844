import Grid from '@mui/material/Grid'
import {
  SimpleForm,
  TextInput,
  ImageInput,
  FileInput,
  usePermissions,
  BooleanInput,
  Labeled,
} from 'react-admin'
import { Box } from '@mui/material'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import CustomImageField from '../../components/CustomImageField'
import CustomFileField from '../../components/CustomFileField'
import { ROLES } from '../../components/constants'

const AdsForm = props => {
  const { ...rest } = props
  const { permissions } = usePermissions()

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={6}>
          <TextInput
            source="name"
            label="Name"
            fullWidth
            variant="standard"
            validate={validateRequired('Title name')}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Labeled label="Status">
              <BooleanInput label="" source="status" />
            </Labeled>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <FileInput
            source="fileUrl"
            label="File"
            multiple={false}
            validate={validateRequired('File')}>
            <CustomFileField
              source="src"
              title="File"
              canDownload={permissions === ROLES.ADMIN}
            />
          </FileInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default AdsForm
