import {
  TextInput,
  ImageInput,
  maxLength,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  SelectInput,
  TabbedForm,
  FormTab,
  List,
  TextField,
  Datagrid,
  BooleanInput,
  ReferenceField,
  useRecordContext,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  useCreate,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRefresh,
  useNotify,
  DateField,
  SimpleShowLayout,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
} from 'react-admin'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

import CustomImageField from '../../components/CustomImageField'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const validateMaxLength = maxLength(
  200,
  'Do not enter more than 200 characters',
)

const statusOptions = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'inactive',
    name: 'Inactive',
  },
]

const AssignToolbar = ({ type }) => {
  const [create] = useCreate()
  const refresh = useRefresh()
  const notify = useNotify()

  const { id } = useParams()
  const { getValues } = useFormContext()

  const onSubmit = async e => {
    e.preventDefault()

    const { entityId } = getValues()
    const organizationId = parseInt(id)

    create(
      'b2b-organization-contents',
      {
        data: {
          organizationId,
          entityId,
          type,
        },
      },
      {
        onSuccess: () => {
          refresh()
          notify('Content assigned successfully')
        },
        onError: error => {
          notify(`${error.message}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Toolbar>
      <SaveButton type="button" onClick={onSubmit} />
    </Toolbar>
  )
}

const AssignAside = ({ type, reference }) => {
  return (
    <Box sx={{ margin: '0.5rem', width: 400 }}>
      <SimpleForm toolbar={<AssignToolbar type={type} />}>
        <ReferenceInput
          label="Assign content"
          source={'entityId'}
          reference={reference}>
          <AutocompleteInput
            optionText="title"
            variant="standard"
            filterToQuery={searchText => ({
              title: { $iLike: `%${searchText}%` },
            })}
          />
        </ReferenceInput>
      </SimpleForm>
    </Box>
  )
}

const ActivityDetails = () => {
  const record = useRecordContext()

  switch (record.type) {
    case 'create_organization':
      return (
        <Stack spacing={1}>
          <ul style={{ padding: '0 10px' }}>
            <li>Company name: {record.newValue.newOrganization.name}</li>
            <li>Number of seats: {record.newValue.newOrganization.seat}</li>
            <li>Start date: {record.newValue.newOrganization.startDate}</li>
            <li>End date: {record.newValue.newOrganization.endDate}</li>
          </ul>
        </Stack>
      )
    case 'update_seat':
      return (
        <Stack spacing={1}>
          <ul style={{ padding: '0 10px' }}>
            <li>Old: {record.oldValue.totalSeats}</li>
            <li>New: {record.newValue.totalSeats}</li>
          </ul>
        </Stack>
      )
    case 'update_start_date':
      return (
        <Stack spacing={1}>
          <ul style={{ padding: '0 10px' }}>
            <li>Old: {record.oldValue.startDate}</li>
            <li>New: {record.newValue.startDate}</li>
          </ul>
        </Stack>
      )
    case 'update_end_date':
      return (
        <Stack spacing={1}>
          <ul style={{ padding: '0 10px' }}>
            <li>Old: {record.oldValue.endDate}</li>
            <li>New: {record.newValue.endDate}</li>
          </ul>
        </Stack>
      )
    case 'remove_audio_book':
      return (
        <SimpleShowLayout>
          <ReferenceArrayField
            reference="books"
            record={record}
            source="newValue.removeBookIds"
            label="Books">
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceArrayField>
        </SimpleShowLayout>
      )
    case 'added_audio_book':
      return (
        <SimpleShowLayout>
          <ReferenceArrayField
            reference="books"
            record={record}
            source="newValue.newBookIds"
            label="Books">
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceArrayField>
        </SimpleShowLayout>
      )
    case 'remove_podcourse':
      return (
        <Stack spacing={1}>
          <SimpleShowLayout>
            <ReferenceArrayField
              reference="courses"
              record={record}
              source="newValue.removeCourseIds"
              label="Courses">
              <SingleFieldList>
                <ChipField source="title" />
              </SingleFieldList>
            </ReferenceArrayField>
          </SimpleShowLayout>
        </Stack>
      )
    case 'added_podcourse':
      return (
        <Stack spacing={1}>
          <SimpleShowLayout>
            <ReferenceArrayField
              reference="courses"
              record={record}
              source="newValue.newCourseIds"
              label="Courses">
              <SingleFieldList>
                <ChipField source="title" />
              </SingleFieldList>
            </ReferenceArrayField>
          </SimpleShowLayout>
        </Stack>
      )
    case 'added_admin_user':
      return (
        <Stack spacing={1}>
          <SimpleShowLayout>
            <ReferenceArrayField
              reference="b2b-users"
              record={record}
              source="newValue.newUserIds"
              label="User">
              <SingleFieldList>
                <ChipField source="email" />
              </SingleFieldList>
            </ReferenceArrayField>
          </SimpleShowLayout>
        </Stack>
      )
    case 'remove_admin_user':
      return (
        <Stack spacing={1}>
          <SimpleShowLayout>
            <FunctionField
              record={record}
              source="newValue.removeUserEmails"
              label="Users"
              render={record =>
                `${record.newValue.removeUserEmails?.join(', ') || ''}`
              }
            />
          </SimpleShowLayout>
        </Stack>
      )
  }

  return ''
}

const B2BOrganizationForm = props => {
  const { ...rest } = props
  const record = useRecordContext()
  const organizationId = record?.id

  return (
    <TabbedForm {...rest} redirect="list">
      <FormTab
        label="Thông tin công ty"
        redirect="list"
        variant="standard"
        margin="normal">
        <GridContainer>
          <Grid item xs={6}>
            <TextInput
              name="name"
              source="name"
              label="Tên công ty"
              fullWidth
              variant="standard"
              validate={[validateRequired('Tên công ty'), validateMaxLength]}
            />
          </Grid>

          <Grid item xs={12}>
            <ArrayInput label="Admin Email" source="adminEmails" fullWidth>
              <SimpleFormIterator>
                <TextInput label="Email" />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>

          <Grid item xs={12}>
            <ImageInput
              source="logo"
              label="Logo"
              accept="image/*"
              multiple={false}
              validate={validateRequired('Logo')}>
              <CustomImageField source="src" title="Logo" />
            </ImageInput>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Thông tin hợp đồng</Typography>
          </Grid>

          <Grid item xs={3}>
            <NumberInput
              source="totalSeats"
              label="Số Slot"
              fullWidth
              variant="standard"
              validate={[validateRequired('Số Slot')]}
            />
          </Grid>

          <Grid item xs={3}>
            <SelectInput
              label="Status"
              source="status"
              choices={statusOptions}
              defaultValue="active"
              alwaysOn
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <DateInput
              source="startDate"
              label="Ngày bắt đầu"
              fullWidth
              validate={[validateRequired('Ngày bắt đầu')]}
            />
          </Grid>

          <Grid item xs={3}>
            <DateInput
              source="endDate"
              label="Ngày kết thúc"
              fullWidth
              validate={[validateRequired('Ngày kết thúc')]}
            />
          </Grid>
          {/*<Grid item xs={6}>*/}
          {/*  <BooleanInput*/}
          {/*    source="isDemo"*/}
          {/*    lable="Is Demo Organization"*/}
          {/*    title="Is Demo Organization"*/}
          {/*    name="isDemo"/>*/}
          {/*</Grid>*/}
        </GridContainer>
      </FormTab>
      <FormTab label="Danh sách PodCourse" variant="filled" margin="normal">
        {!organizationId && (
          <Typography variant="body1">
            Bạn phải lưu "Thông tin công ty" trước khi cập nhật danh sách nội
            dung!
          </Typography>
        )}
        {organizationId && (
          <List
            sx={{ width: '100%' }}
            disableSyncWithLocation
            actions={null}
            resource="b2b-organization-contents"
            basePath="/b2b-organization-contents"
            aside={<AssignAside type="course" reference="courses" />}
            filter={{
              organizationId: organizationId,
              isRequiredOrganizationId: true,
              type: 'course',
            }}>
            <Datagrid rowClick={false}>
              <ReferenceField
                source="courseId"
                reference="courses"
                label="Course">
                <TextField source="title" />
              </ReferenceField>
            </Datagrid>
          </List>
        )}
      </FormTab>

      <FormTab label="Danh sách Sách Nói" variant="filled" margin="normal">
        {!organizationId && (
          <Typography variant="body1">
            Bạn phải lưu "Thông tin công ty" trước khi cập nhật danh sách nội
            dung!
          </Typography>
        )}
        {organizationId && (
          <List
            sx={{ width: '100%' }}
            disableSyncWithLocation
            actions={null}
            resource="b2b-organization-contents"
            basePath="/b2b-organization-contents"
            aside={<AssignAside type="book" reference="books" />}
            filter={{
              organizationId: organizationId,
              isRequiredOrganizationId: true,
              type: 'book',
            }}>
            <Datagrid rowClick={false}>
              <TextField source="id" label="ID" />
              <ReferenceField source="bookId" reference="books" label="Book">
                <TextField source="title" />
              </ReferenceField>
              <EditButton basePath="/b2b-organization-contents" />
            </Datagrid>
          </List>
        )}
      </FormTab>
      <FormTab label="Nội dung khác" variant="filled" margin="normal">
        <GridContainer>
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Typography variant="h6">Nội dung khác</Typography>
          </Grid>
          <Grid item xs={6}>
            <BooleanInput
              source="includeEnglishBook"
              lable="English"
              title="English"
            />
          </Grid>
          <Grid item xs={6}>
            <BooleanInput
              source="includeMeditation"
              lable="Meditation"
              title="Meditation"
            />
          </Grid>
        </GridContainer>
      </FormTab>
      <FormTab label="Dữ liệu hoạt động" variant="filled" margin="normal">
        {!organizationId && (
          <Typography variant="body1">
            Bạn phải lưu "Thông tin công ty" trước khi cập nhật danh sách nội
            dung!
          </Typography>
        )}
        {organizationId && (
          <List
            sx={{ width: '100%' }}
            disableSyncWithLocation
            actions={null}
            resource="b2b-organization-activities"
            basePath="/b2b-organization-activities"
            filter={{
              organizationId: organizationId,
            }}>
            <Datagrid rowClick={false}>
              <DateField source="createdAt" label="Time" />
              <TextField source="createdBy" label="Action By" />
              <TextField source="event" label="Event" />
              <ActivityDetails source="id" label="Details" />
            </Datagrid>
          </List>
        )}
      </FormTab>
    </TabbedForm>
  )
}

export default B2BOrganizationForm
