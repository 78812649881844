import { FunctionField, useRecordContext } from 'react-admin'

import { ROLES } from '../constants'

const APPROVE_STATUS = {
  [ROLES.ADMIN]: 'Approved by Admin',
  [ROLES.STATISTICAL_STAFF]: 'Approved by Accounting',
  [ROLES.MARKETING_MANAGER]: 'Approved by Manager',
  [ROLES.HEAD_OF_CUSTOMER_SERVICE]: 'Approved by Head of CS',
  [ROLES.CS_MARKETING_MANAGER]: 'Approved by CS & Marketing Manager',
}

const ApproveStatus = props => {
  const { statusField = 'status', ...rest } = props
  const record = useRecordContext()

  return (
    <FunctionField
      {...rest}
      render={() => {
        switch (record[statusField]) {
          case 'under_review':
            return 'Wait for approval'
          case 'approved':
            return APPROVE_STATUS[record.approveRole] || 'Approved'
          default:
            return 'Rejected'
        }
      }}
    />
  )
}

export default ApproveStatus
