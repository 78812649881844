import { Create } from 'react-admin'

import FeaturedContentForm from './FeaturedContentForm'

const FeaturedContentCreate = props => (
  <Create redirect="list" {...props}>
    <FeaturedContentForm />
  </Create>
)

export default FeaturedContentCreate
