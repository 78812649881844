import { Edit } from 'react-admin'

import FeaturedContentForm from './FeaturedContentForm'

const FeaturedContentEdit = props => {
  return (
    <Edit mutationMode="undoable" {...props}>
      <FeaturedContentForm permissions={props.permissions} />
    </Edit>
  )
}

export default FeaturedContentEdit
