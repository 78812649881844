import { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  DeleteButton,
  ReferenceInput,
  TextInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  usePermissions,
  useRecordContext,
  AutocompleteInput,
} from 'react-admin'

import BookActionButton from '../../components/BookActionButton'
import BookStatusField from '../../components/BookStatusField'
import FilterComponent from '../../components/Filter'
import { ROLES } from '../../components/constants'

const Actions = props => {
  const { resource, currentSort, filterValues, exporter, basePath } = props
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={false}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={10000}
      />
    </TopToolbar>
  )
}

const BookFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
    <ReferenceInput
      label="Publisher"
      source="publisherId"
      reference="users"
      filter={{ role: 'publisher' }}
      alwaysOn>
      <AutocompleteInput
        optionText="name"
        filterToQuery={searchText => ({
          name: { $iLike: `%${searchText}%` },
          role: ROLES.PUBLISHER,
        })}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Author"
      source="authorId"
      reference="authors"
      alwaysOn>
      <AutocompleteInput
        optionText="name"
        filterToQuery={searchText => ({
          name: { $iLike: `%${searchText}%` },
        })}
      />
    </ReferenceInput>
  </FilterComponent>
)

const ActionField = () => {
  const record = useRecordContext()
  const { permissions } = usePermissions()
  if (!record || permissions !== ROLES.ADMIN) return null

  switch (record.status) {
    case 'Under review':
      return (
        <Fragment>
          <BookActionButton
            label="Approve"
            action="approve"
            color="primary"
            record={record}
          />
          <BookActionButton
            label="Reject"
            action="reject"
            color="secondary"
            record={record}
          />
        </Fragment>
      )

    default:
      return <DeleteButton label="Delete" record={record} undoable={false} />
  }
}

const BookList = ({ ...props }) => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      filters={<BookFilter />}
      actions={<Actions />}
      sort={{ field: 'updatedAt', order: 'DESC' }}>
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="title" />
        <ReferenceField source="authorId" reference="authors" label="Author">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="publisherId"
          reference="users"
          label="Publisher">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="datePublished" label="Publish Date" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <BookStatusField disabled={permissions !== ROLES.ADMIN} />
        <EditButton />
        <ActionField />
      </Datagrid>
    </List>
  )
}

export default BookList
