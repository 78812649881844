import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const OrgContentFilter = props => (
  <FilterComponent {...props}>
    <ReferenceInput
      label="Organization"
      source="organizationId"
      reference="b2b-organizations"
      alwaysOn>
      <AutocompleteInput
        optionText="name"
        filterToQuery={searchText => ({
          name: { $iLike: `%${searchText}%` },
        })}
      />
    </ReferenceInput>
  </FilterComponent>
)

const B2BOrganizationContentList = props => (
  <List
    {...props}
    filters={<OrgContentFilter />}
    bulkActionButtons={false}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={true}>
      <TextField source="id" label="ID" />
      <ReferenceField
        source="organizationId"
        reference="b2b-organizations"
        label="Organization">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="bookId" reference="books" label="Book">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="courseId" reference="courses" label="Course">
        <TextField source="title" />
      </ReferenceField>
      <EditButton basePath="/b2b-organization-contents" />
    </Datagrid>
  </List>
)

export default B2BOrganizationContentList
