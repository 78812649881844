import React, { Fragment, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import feathersClient from './feathersClient'
import Loading from './components/Loading'

const withAwsWaf = Component => {
  const WrapperComponent = props => {
    const [loading, setLoading] = React.useState(
      process.env.REACT_APP_ENV === 'production',
    )

    const onLoadDone = useCallback(() => {
      feathersClient.init()
      setLoading(false)
    }, [])

    useEffect(() => {
      if (process.env.REACT_APP_ENV !== 'production')
        return feathersClient.init()

      setTimeout(() => {
        onLoadDone()
      }, 5000)
    }, [onLoadDone])

    return (
      <Fragment>
        {process.env.REACT_APP_ENV === 'production' && (
          <Helmet>
            <script
              id="aws-waf"
              type="text/javascript"
              src="https://e9deaf1d52ad.ap-southeast-1.sdk.awswaf.com/e9deaf1d52ad/9e91bc5155f7/challenge.js"
              fetchPriority="high"
              onLoad={onLoadDone}
              onError={onLoadDone}
            />
          </Helmet>
        )}
        {!loading ? <Component {...props} /> : <Loading />}
      </Fragment>
    )
  }

  return WrapperComponent
}

export default withAwsWaf
