import {
  TextInput,
  SimpleForm,
  useNotify,
  useDataProvider,
  SaveButton,
  Button,
  Link,
  Toolbar,
} from 'react-admin'
import qs from 'query-string'
import { Box, Card, CardContent, CardHeader } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { validateRequired } from '../utils/validate'

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url('https://picsum.photos/1600/900')`,
  },
  card: {
    minWidth: 400,
    marginTop: '6em',
  },
}

const CustomToolBar = props => (
  <Toolbar
    {...props}
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '1rem 0',
    }}>
    <SaveButton label="Reset password" />
    <Button>
      <Link to="/login">Login</Link>
    </Button>
  </Toolbar>
)

const ResetPasswordPage = () => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const location = useLocation()

  const handleSubmit = data => {
    return dataProvider
      .create('authentication/resetPassword', {
        data: {
          token: qs.parse(location.search, { ignoreQueryPrefix: true }).token,
          password: data.password,
        },
      })
      .then(() => {
        window.location.href = '/#/login'
        notify('Change password successfully', { type: 'success' })
      })
      .catch(error => {
        notify(error.message, { type: 'error' })
      })
  }

  return (
    <Box sx={styles.main}>
      <Card sx={styles.card}>
        <CardHeader title="Reset password" />
        <CardContent>
          <SimpleForm onSubmit={handleSubmit} toolbar={<CustomToolBar />}>
            <TextInput
              label="New Password"
              source="password"
              type="password"
              validate={validateRequired('Password')}
            />
          </SimpleForm>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ResetPasswordPage
