import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import auth from '@feathersjs/authentication-client'

const host = process.env.REACT_APP_SERVER_HOST

class FeatherClient {
  constructor() {
    this.app = null
  }

  init() {
    this.app = feathers()
    const restClient = rest(host)
    const fetchFn = window.AwsWafIntegration
      ? window.AwsWafIntegration.fetch
      : window.fetch

    this.app.configure(restClient.fetch(fetchFn.bind(window)))
    this.app.configure(auth())
    this.app.hooks({
      before: {
        all: [
          context => {
            const token = window.localStorage.getItem('feathers-jwt')
            if (token && context.app.rest) {
              const authHeader = `Bearer ${token}`

              context.params.headers = Object.assign(
                {},
                {
                  Authorization: authHeader,
                },
                context.params.headers,
              )
            }
          },
        ],
      },
    })
  }

  get() {
    if (!this.app) {
      this.init()
    }

    return this.app
  }
}

export default new FeatherClient()
