import { TextInput, FileInput, SimpleForm, usePermissions } from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomFileField from '../../components/CustomFileField'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import { ROLES } from '../../components/constants'
import GridContainer from '../../components/GridContainer'

const MeditationBackgroundForm = ({ ...props }) => {
  const { permissions } = usePermissions()
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={[validateRequired('Name')]}
          />
        </Grid>

        <Grid item xs={12}>
          <FileInput
            source="fileUrl"
            label="File meditation background"
            multiple={false}
            validate={validateRequired('File Meditation')}>
            <CustomFileField
              source="src"
              title="Meditation background"
              canDownload={permissions === ROLES.ADMIN}
            />
          </FileInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default MeditationBackgroundForm
