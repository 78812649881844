import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  DeleteButton,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const B2BTrialUserFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <ReferenceInput
      label="Organization"
      source="organizationId"
      reference="b2b-organizations"
      alwaysOn>
      <AutocompleteInput
        optionText="name"
        filterToQuery={searchText => ({
          name: { $iLike: `%${searchText}%` },
        })}
      />
    </ReferenceInput>
  </FilterComponent>
)

const B2BTrialUserList = props => (
  <List
    {...props}
    filters={<B2BTrialUserFilter />}
    bulkActionButtons={false}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={true}>
      <TextField source="id" label="ID" />
      <ReferenceField
        reference="b2b-organizations"
        source="organization.id"
        label="Organization ID">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="email" />
      <DateField source="createdAt" />
      <DeleteButton basePath="/b2b-trial-users" />
    </Datagrid>
  </List>
)

export default B2BTrialUserList
