import { useCallback } from 'react'
import {
  Button,
  useRefresh,
  useUpdate,
  useRedirect,
  useNotify,
  usePermissions,
} from 'react-admin'

import { ROLES } from '../constants'

const ActionButton = props => {
  const { record, action, service, statusField = 'status', ...rest } = props
  const { permissions } = usePermissions()

  const refresh = useRefresh()
  const redirect = useRedirect()
  const notify = useNotify()

  const getDataUpdate = () => {
    switch (action) {
      case 'approve':
        return {
          approveRole: permissions,
          ...([
            ROLES.ADMIN,
            ROLES.STATISTICAL_STAFF,
            ROLES.MARKETING_MANAGER,
            ROLES.HEAD_OF_CUSTOMER_SERVICE,
            ROLES.CS_MARKETING_MANAGER,
          ].includes(permissions) && {
            [statusField]: 'approved',
          }),
        }
      case 'reject':
        return {
          [statusField]: 'rejected',
        }

      default:
        return {}
    }
  }

  const data = getDataUpdate()

  const [approve] = useUpdate(
    service,
    { id: record.id, data },
    {
      onSuccess: () => {
        redirect(`/${service}`)
        notify(`Request is ${data.status}`, { type: 'info' })
        refresh()
      },
      onFailure: error => {
        notify(`Failure: ${error.message}`, { type: 'warning' })
      },
    },
  )

  const handleClick = useCallback(() => {
    approve()
  }, [approve])

  return (
    <Button
      sx={{ margin: '5px 0', minWidth: 90 }}
      variant="contained"
      onClick={handleClick}
      {...rest}
    />
  )
}

export default ActionButton
