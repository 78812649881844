import {
  TextInput,
  FileInput,
  ImageInput,
  ReferenceArrayInput,
  SelectArrayInput,
  maxLength,
  SimpleForm,
  SelectInput,
  usePermissions,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomFileField from '../../components/CustomFileField'
import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import { requiredArray, validateRequired } from '../../utils/validate'
import { ROLES } from '../../components/constants'
import GridContainer from '../../components/GridContainer'

const isFree = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]
const statuses = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]

const SleepStoryForm = () => {
  const { permissions } = usePermissions()
  const validateMaxLength = maxLength(
    255,
    'Do not enter more than 255 characters',
  )

  // const validateDescription = [
  //   validateRequired('Description'),
  //   minLength(20),
  //   maxLength(2000, 'Do not enter more than 2000 characters'),
  // ]
  return (
    <SimpleForm
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="title"
            fullWidth
            variant="standard"
            validate={[validateRequired('Title'), validateMaxLength]}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Is free?"
            source="isFree"
            choices={isFree}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Is Free SleepStory')}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Publish"
            source="status"
            choices={statuses}
            default={false}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Publish')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="voice"
            fullWidth
            variant="standard"
            validate={[validateRequired('Voice'), validateMaxLength]}
          />
        </Grid>

        <Grid item xs={12}>
          <ReferenceArrayInput
            label="Categories"
            source="categoryIds"
            reference="categories"
            filter={{ type: 'sleep_story' }}
            validate={requiredArray('Category')}>
            <SelectArrayInput
              optionText="name"
              fullWidth
              variant="standard"
              required
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <FileInput
            source="fileUrl"
            label="File SleepStory"
            multiple={false}
            validate={validateRequired('File SleepStory')}>
            <CustomFileField
              source="src"
              title="File sample"
              canDownload={permissions === ROLES.ADMIN}
            />
          </FileInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default SleepStoryForm
