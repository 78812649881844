import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  EditButton,
  usePermissions,
} from 'react-admin'

import ApproveButtons from '../../components/ApproveAction/ApproveButtons'
import ApproveStatus from '../../components/ApproveAction/ApproveStatus'
import { ROLES } from '../../components/constants'
import CustomSwitchField from '../../components/CustomSwitchField'
import CustomTextField from '../../components/CustomTextField'
// import DeleteButton from '../../components/DeleteButton'

const DiscountsList = props => {
  const { permissions } = usePermissions()

  return (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="code" label="Promo code" />
        <TextField source="productType" label=" Product type" />
        <TextField source="discountType" />
        <TextField source="value" />
        <DateField source="expiredAt" />
        <CustomSwitchField
          resource="discounts"
          label="Status"
          switchField="status"
          disabled={record =>
            ![
              ROLES.ADMIN,
              ROLES.CPSO,
              ROLES.MARKETING_MANAGER,
              ROLES.STATISTICAL_STAFF,
              ROLES.HEAD_OF_CUSTOMER_SERVICE,
              ROLES.CS_MARKETING_MANAGER,
            ].includes(permissions) || record.approveStatus !== 'approved'
          }
        />
        <CustomTextField
          source="affiliateId"
          reference="users"
          label="Affiliate"
          referenceField="name"
          {...props}
        />

        <ReferenceField source="createdBy" reference="users" label="Created by">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="updatedBy" reference="users" label="Updated by">
          <TextField source="name" />
        </ReferenceField>

        <ApproveStatus label="Status" statusField="approveStatus" />

        <ReferenceField source="employeeId" reference="users" label="Employee">
          <TextField source="name" />
        </ReferenceField>

        <ApproveButtons
          permissions={props.permissions}
          service="discounts"
          statusField="approveStatus"
        />

        <EditButton />
        {/* <DeleteButton permissions={props.permissions} /> */}
      </Datagrid>
    </List>
  )
}

export default DiscountsList
