import {
  TextInput,
  FileInput,
  ImageInput,
  ReferenceArrayInput,
  SelectArrayInput,
  maxLength,
  SimpleForm,
  SelectInput,
  NumberInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomFileField from '../../components/CustomFileField'
import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import { requiredArray, validateRequired } from '../../utils/validate'

const isFree = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]
const isShow = [
  { id: true, name: 'Show' },
  { id: false, name: 'Hide' },
]

const ArticleForm = props => {
  const validateMaxLength = maxLength(
    200,
    'Do not enter more than 200 characters',
  )

  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        {props.editing && (
          <Grid item xs={12}>
            <TextInput
              source="embedUrl"
              fullWidth
              variant="standard"
              disabled
            />
          </Grid>
        )}
        <Grid item xs={8}>
          <TextInput
            source="title"
            fullWidth
            variant="standard"
            validate={[validateRequired('Title'), validateMaxLength]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Is free?"
            source="isFree"
            choices={isFree}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Is Free Article')}
          />
        </Grid>

        <Grid item xs={8}>
          <NumberInput
            label="Episode number"
            source="issueNumber"
            fullWidth
            variant="standard"
            validate={validateRequired('Episode number')}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Status"
            source="status"
            choices={isShow}
            fullWidth
            variant="standard"
            required
            defaultValue={true}
          />
        </Grid>

        <Grid item xs={12}>
          <ReferenceArrayInput
            label="Audio series"
            source="audioSeriesIds"
            reference="audio-series"
            validate={requiredArray('Audio series')}>
            <SelectArrayInput
              optionText="name"
              fullWidth
              variant="standard"
              required
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <FileInput
            source="fileUrl"
            label="File audio"
            multiple={false}
            validate={validateRequired('File Audio')}>
            <CustomFileField source="src" title="File sample" />
          </FileInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default ArticleForm
