import {
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  BooleanField,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'

const Title = ({ record }) => {
  return <span>User {record ? `${record.email}` : ''}</span>
}

const B2BUserShow = props => {
  const { tabSyncWithLocation = true } = props

  return (
    <Show title={<Title />} {...props}>
      <TabbedShowLayout syncWithLocation={tabSyncWithLocation}>
        <Tab label="Thông tin">
          <Grid container>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="id" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="email" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="role" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="status" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <BooleanField source="isTrial" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <ReferenceField
                  reference="b2b-organizations"
                  source="organization.id">
                  <TextField source="name" />
                </ReferenceField>
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <DateField source="createdAt" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="lastLoginAt" />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
        <Tab label="Nhóm" source="groups">
          <ArrayField
            source="groups"
            label=""
            sx={{
              '&> div': {
                width: '100%',
              },
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <TextField source="id" label="ID" />
              <TextField source="name" label="Group Name" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Danh sách PodCourse" variant="filled" margin="normal">
          <ArrayField
            source="courses"
            label=""
            sx={{
              '&> div': {
                width: '100%',
              },
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <TextField source="id" label="ID" />
              <ReferenceField reference="courses" source="id" label="Title">
                <TextField source="title" />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Danh sách Sách Nói" variant="filled" margin="normal">
          <ArrayField
            source="books"
            label=""
            sx={{
              '&> div': {
                width: '100%',
              },
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <TextField source="id" label="ID" />
              <ReferenceField reference="books" source="id" label="Title">
                <TextField source="title" />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Nội dung khác" variant="filled" margin="normal">
          <GridContainer>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <BooleanField
                  source="includeEnglishBook"
                  lable="English"
                  title="English"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <BooleanField
                  source="includeMeditation"
                  lable="Meditation"
                  title="Meditation"
                />
              </SimpleShowLayout>
            </Grid>
          </GridContainer>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default B2BUserShow
