import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  TextInput,
  BooleanField,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const Filter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
  </FilterComponent>
)

const AdsList = () => {
  return (
    <List
      filters={<Filter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <BooleanField
          source="status"
          valueLabelTrue="active"
          valueLabelFalse="inactive"
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default AdsList
