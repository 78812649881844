import { Card, CardContent, CardHeader, Button, Box } from '@mui/material'
import {
  TextInput,
  SimpleForm,
  useNotify,
  email,
  SaveButton,
  Toolbar,
  useDataProvider,
} from 'react-admin'
import { Link } from 'react-router-dom'

import { validateRequired } from '../utils/validate'

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url('https://picsum.photos/1600/900')`,
  },
  card: {
    minWidth: 400,
    marginTop: '6em',
  },
}

const CustomToolBar = props => (
  <Toolbar
    {...props}
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '1rem 0',
    }}>
    <SaveButton label="Reset password" />
    <Button>
      <Link to="/login">Login</Link>
    </Button>
  </Toolbar>
)

const ForgotPasswordPage = () => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const handleSubmit = data => {
    return dataProvider
      .create('authentication/sendResetPassword', {
        data: {
          email: data.email,
        },
      })
      .then(() => {
        notify('Send request successfully. Please check your email inbox', {
          type: 'success',
        })
      })
      .catch(error => {
        notify(error.message, { type: 'error' })
      })
  }

  return (
    <Box sx={styles.main}>
      <Card sx={styles.card}>
        <CardHeader
          title="Forgot password"
          subheader="Enter your email to reset your password."
        />
        <CardContent>
          <SimpleForm onSubmit={handleSubmit} toolbar={<CustomToolBar />}>
            <TextInput
              label="Email"
              source="email"
              type="email"
              fullWidth
              validate={[validateRequired('Email'), email()]}
            />
          </SimpleForm>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ForgotPasswordPage
