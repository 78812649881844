import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  DateField,
  FunctionField,
} from 'react-admin'

const FeaturedContentList = props => (
  <List {...props} perPage={10} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="id" />
      <FunctionField
        source="names"
        render={record => record.names.join(', ')}
      />
      <TextField source="status" />
      <DateField source="createdAt" />
      <EditButton />
      <DeleteButton mutationMode="undoable" />
    </Datagrid>
  </List>
)

export default FeaturedContentList
