import { Edit } from 'react-admin'

import AdsForm from './AdsForm'

const AdsEdit = props => (
  <Edit undoable={false} {...props}>
    <AdsForm />
  </Edit>
)

export default AdsEdit
